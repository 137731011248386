<template>
  <div>
    <div
      id="carouselExampleIndicators"
      class="carousel carousel-dark slide"
      data-bs-ride="carousel"
    >
      <div class="carousel-indicators">
        <button
          type="button"
          data-bs-target="#carouselExampleIndicators"
          data-bs-slide-to="0"
          class="active"
          aria-current="true"
          aria-label="Slide 1"
        ></button>
        <button
          type="button"
          data-bs-target="#carouselExampleIndicators"
          data-bs-slide-to="1"
          aria-label="Slide 2"
        ></button>
        <button
          type="button"
          data-bs-target="#carouselExampleIndicators"
          data-bs-slide-to="2"
          aria-label="Slide 3"
        ></button>
      </div>
      <div class="carousel-inner">
        <div class="carousel-item active" data-bs-interval="12000">
          <img
            :src="getImage('carousel-01.webp')"
            class="d-block w-100 h-100 img-full"
            alt="..."
          />
          <img
            :src="getImage('carousel-01-res.webp')"
            class="d-block w-100 h-100 img-responsive"
            alt="..."
          />
        </div>
        <div class="carousel-item" data-bs-interval="10000">
          <img
            :src="getImage('carousel-02.webp')"
            class="d-block w-100 h-100 img-full"
            alt="..."
          />
          <img
            :src="getImage('carousel-02-res.webp')"
            class="d-block w-100 h-100 img-responsive"
            alt="..."
          />
        </div>
        <div class="carousel-item" data-bs-interval="8000">
          <img
            :src="getImage('carousel-03.webp')"
            class="d-block w-100 h-100 img-full"
            alt="..."
          />
          <img
            :src="getImage('carousel-03-res.webp')"
            class="d-block w-100 h-100 img-responsive"
            alt="..."
          />
        </div>
      </div>
      <button
        class="carousel-control-prev"
        type="button"
        data-bs-target="#carouselExampleIndicators"
        data-bs-slide="prev"
      >
        <span class="carousel-control-prev-icon" aria-hidden="true"></span>
        <span class="visually-hidden">Previous</span>
      </button>
      <button
        class="carousel-control-next"
        type="button"
        data-bs-target="#carouselExampleIndicators"
        data-bs-slide="next"
      >
        <span class="carousel-control-next-icon" aria-hidden="true"></span>
        <span class="visually-hidden">Next</span>
      </button>
    </div>

    <CardsModulos />

    <Banner
      imgUrl="banner-01.webp"
      :classes="{ div: 'pointer', img: 'w-100 img-full', p: '' }"
      link="HangPack"
    />

    <Banner
      imgUrl="banner-01-res.webp"
      :classes="{ div: 'pointer', img: 'w-100 img-responsive', p: '' }"
      link="HangPack"
    />

    <Banner imgUrl="banner-02.webp" :classes="{ div: '', img: '', p: '' }" />

    <Section
      :title="$t('home.cuidandoTuSalud.title')"
      :text="$t('home.cuidandoTuSalud.texto')"
      link="cuidando-tu-salud"
      collapsedText=""
    />

    <Banner
      imgUrl="banner-03.webp"
      :classes="{ div: 'pointer', img: 'w-100 img-full', p: '' }"
      link="Videos"
    />

    <Banner
      imgUrl="banner-03-res.webp"
      :classes="{ div: 'pointer', img: 'w-100 img-responsive', p: '' }"
      link="Videos"
    />
  </div>
</template>

<script>
import CardsModulos from "@/components/CardsModulos.vue";
import Section from "@/components/Section.vue";
import Banner from "@/components/Banner.vue";
import router from "../router/index.js";

export default {
  name: "Home",
  components: {
    CardsModulos,
    Section,
    Banner,
  },
  data() {
    return {
      baseUrl: router.options.base,
    };
  },
  methods: {
    getImage(fileName) {
      return require("../assets/images/carousel/" +
        this.$i18n.locale +
        "/" +
        fileName);
    },
  },
  computed: {
    currentLocale() {
      return this.$i18n.locale;
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/assets/scss/constants.scss";

.carousel-caption {
  // color: gray !important;
  // text-align: left !important;
  // font-size: small;
  color: $gris !important;
  width: 24% !important;
  top: 10%;
  bottom: auto;
  line-height: 1rem;
  font-size: 0.9rem !important;
  text-align: justify !important;
  text-justify: inter-word;
  display: inline-block;
  // padding-left: 25px;
}

.carousel-inner {
  width: 100%;
}

@media (max-width: 425px) {
  .carousel-inner {
    // min-height: 100vh;

    & img {
      min-height: 40vh !important;
      width: auto;
      height: auto;
      object-fit: cover;
    }
  }

  .carousel-caption {
    display: none;
  }
}
</style>
