import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
// import Vademecum from '../views/Vademecum.vue'
// import Productos from '../views/Productos.vue'
// import SustentaVainilla from '../views/Productos/SustentaVainilla.vue'
// import SustentaNeutro from '../views/Productos/SustentaNeutro.vue'
// import SustentaDBNeutro from '../views/Productos/SustentaDBNeutro.vue'
// import SustentaDBVainilla from '../views/Productos/SustentaDBVainilla.vue'
// import SustentaSemielemental from '../views/Productos/SustentaSemielemental.vue'
// import SustentaFibras from '../views/Productos/SustentaFibras.vue'
// import SustentaDessert from '../views/Productos/SustentaDessert.vue'
// import SustentaJuniorSinLactosa from '../views/Productos/SustentaJuniorSinLactosa.vue'
// import SustentaJuniorSemielemental from '../views/Productos/SustentaJuniorSemielemental.vue'
// import Lipidgen from '../views/Productos/Lipidgen.vue'
// import EspesanteResist from '../views/Productos/EspesanteResist.vue'
// import MediumChainLipidsOil from '../views/Productos/MediumChainLipidsOil.vue'
// import MediumChainLipids from '../views/Productos/MediumChainLipids.vue'
// import PoliCarb from '../views/Productos/PoliCarb.vue'
// import Dextrosa from '../views/Productos/Dextrosa.vue'
// import Lactoproteyn from '../views/Productos/Lactoproteyn.vue'
// import Cetogenik from '../views/Productos/Cetogenik.vue'
// import Videos from '../views/Videos.vue'
// import Faq from '../views/Faq.vue'
// import Contacto from '../views/Contacto.vue'
// import PoliticasDeCalidad from '../views/PoliticasDeCalidad.vue'
// import CuidandoTuSalud from '../views/CuidandoTuSalud.vue'
// import ATravesDeLaNutricion from '../views/CuidandoTuSalud/ATravesDeLaNutricion.vue'
// import VidaSaludable from '../views/CuidandoTuSalud/VidaSaludable.vue'
// import EnvejeciendoBien from '../views/CuidandoTuSalud/EnvejeciendoBien.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/productos',
    name: 'Productos',
    component: () => import(/* webpackChunkName: "Productos" */ '../views/Productos.vue')
  },
  {
    path: '/vademecum',
    name: 'Vademecum',
    component: () => import(/* webpackChunkName: "Vademecum" */ '../views/Vademecum.vue')
  },
  {
    path: '/productos/sustenta-neutro',
    name: 'SustentaNeutro',
    component: () => import(/* webpackChunkName: "SustentaNeutro" */ '../views/Productos/SustentaNeutro.vue')
  },
  {
    path: '/productos/sustenta-vainilla',
    name: 'SustentaVainilla',
    component: () => import(/* webpackChunkName: "SustentaVainilla" */ '../views/Productos/SustentaVainilla.vue')
  },
  {
    path: '/productos/sustenta-db-neutro',
    name: 'SustentaDBNeutro',
    component: () => import(/* webpackChunkName: "SustentaDBNeutro" */ '../views/Productos/SustentaDBNeutro.vue')
  },
  {
    path: '/productos/sustenta-db-vainilla',
    name: 'SustentaDBVainilla',
    component: () => import(/* webpackChunkName: "SustentaDBVainilla" */ '../views/Productos/SustentaDBVainilla.vue')
  },
  {
    path: '/productos/sustenta-semielemental',
    name: 'SustentaSemielemental',
    component: () => import(/* webpackChunkName: "SustentaSemielemental" */ '../views/Productos/SustentaSemielemental.vue')
  },
  {
    path: '/productos/sustenta-fibras',
    name: 'SustentaFibras',
    component: () => import(/* webpackChunkName: "SustentaFibras" */ '../views/Productos/SustentaFibras.vue')
  },
  {
    path: '/productos/sustenta-dessert',
    name: 'SustentaDessert',
    component: () => import(/* webpackChunkName: "SustentaDessert" */ '../views/Productos/SustentaDessert.vue')
  },
  {
    path: '/productos/sustenta-junior-sin-lactosa',
    name: 'SustentaJuniorSinLactosa',
    component: () => import(/* webpackChunkName: "SustentaJuniorSinLactosa" */ '../views/Productos/SustentaJuniorSinLactosa.vue')
  },
  {
    path: '/productos/sustenta-junior-semielemental',
    name: 'SustentaJuniorSemielemental',
    component: () => import(/* webpackChunkName: "SustentaJuniorSemielemental" */ '../views/Productos/SustentaJuniorSemielemental.vue')
  },
  {
    path: '/productos/lipidgen',
    name: 'Lipidgen',
    component: () => import(/* webpackChunkName: "Lipidgen" */ '../views/Productos/Lipidgen.vue')
  },
  {
    path: '/productos/espesante-resist',
    name: 'EspesanteResist',
    component: () => import(/* webpackChunkName: "EspesanteResist" */ '../views/Productos/EspesanteResist.vue')
  },
  {
    path: '/productos/:flia/medium-chain-lipids-oil',
    name: 'MediumChainLipidsOil',
    component: () => import(/* webpackChunkName: "MediumChainLipidsOil" */ '../views/Productos/MediumChainLipidsOil.vue')
  },
  {
    path: '/productos/:flia/medium-chain-lipids',
    name: 'MediumChainLipids',
    component: () => import(/* webpackChunkName: "MediumChainLipids" */ '../views/Productos/MediumChainLipids.vue')
  },
  {
    path: '/productos/poli-carb',
    name: 'PoliCarb',
    component: () => import(/* webpackChunkName: "PoliCarb" */ '../views/Productos/PoliCarb.vue')
  },
  {
    path: '/productos/dextrosa',
    name: 'Dextrosa',
    component: () => import(/* webpackChunkName: "Dextrosa" */ '../views/Productos/Dextrosa.vue')
  },
  {
    path: '/productos/lactoproteyn',
    name: 'Lactoproteyn',
    component: () => import(/* webpackChunkName: "Lactoproteyn" */ '../views/Productos/Lactoproteyn.vue')
  },
  {
    path: '/productos/cetogenik',
    name: 'Cetogenik',
    component: () => import(/* webpackChunkName: "Cetogenik" */ '../views/Productos/Cetogenik.vue')
  },
  {
    path: '/productos/hang-pack-neutro',
    name: 'HangPackSustentaNeutro',
    component: () => import(/* webpackChunkName: "HangPackSustentaNeutro" */ '../views/Productos/HangPackSustentaNeutro.vue')
  },
  {
    path: '/productos/hang-pack-junior-sin-lactosa',
    name: 'HangPackJuniorSinLactosa',
    component: () => import(/* webpackChunkName: "HangPackJuniorSinLactosa" */ '../views/Productos/HangPackJuniorSinLactosa.vue')
  },
  {
    path: '/productos/hang-pack-junior-semielemental',
    name: 'HangPackJuniorSemielemental',
    component: () => import(/* webpackChunkName: "HangPackJuniorSemielemental" */ '../views/Productos/HangPackJuniorSemielemental.vue')
  },
  {
    path: '/productos/hang-pack-sustenta-semielemental',
    name: 'HangPackSustentaSemielemental',
    component: () => import(/* webpackChunkName: "HangPackSustentaSemielemental" */ '../views/Productos/HangPackSustentaSemielemental.vue')
  },
  {
    path: '/productos/hang-pack-sustenta-db-neutro',
    name: 'HangPackSustentaDBNeutro',
    component: () => import(/* webpackChunkName: "HangPackSustentaDBNeutro" */ '../views/Productos/HangPackSustentaDBNeutro.vue')
  },
  {
    path: '/productos/fibras',
    name: 'Fibras',
    component: () => import(/* webpackChunkName: "EspesanteResist" */ '../views/Productos/Fibras.vue')
  },
  {
    path: '/productos/espesante-xanthan',
    name: 'EspesanteXanthan',
    component: () => import(/* webpackChunkName: "EspesanteResist" */ '../views/Productos/EspesanteXanthan.vue')
  },
  {
    path: '/videos',
    name: 'Videos',
    component: () => import(/* webpackChunkName: "Videos" */ '../views/Videos.vue')
  },
  {
    path: '/faq',
    name: 'Faq',
    component: () => import(/* webpackChunkName: "Faq" */ '../views/Faq.vue')
  },
  {
    path: '/politicas-de-calidad',
    name: 'PoliticasDeCalidad',
    component: () => import(/* webpackChunkName: "PoliticasDeCalidad" */ '../views/PoliticasDeCalidad.vue')
  },
  {
    path: '/contacto',
    name: 'Contacto',
    component: () => import(/* webpackChunkName: "Contacto" */ '../views/Contacto.vue')
  },
  {
    path: '/cuidando-tu-salud',
    name: 'CuidandoTuSalud',
    component: () => import(/* webpackChunkName: "CuidandoTuSalud" */ '../views/CuidandoTuSalud.vue')
  },
  {
    path: '/cuidando-tu-salud/a-traves-de-la-nutricion',
    name: 'ATravesDeLaNutricion',
    component: () => import(/* webpackChunkName: "ATravesDeLaNutricion" */ '../views/CuidandoTuSalud/ATravesDeLaNutricion.vue')
  },
  {
    path: '/cuidando-tu-salud/vida-saludable',
    name: 'VidaSaludable',
    component: () => import(/* webpackChunkName: "VidaSaludable" */ '../views/CuidandoTuSalud/VidaSaludable.vue')
  },
  {
    path: '/cuidando-tu-salud/envejeciendo-bien',
    name: 'EnvejeciendoBien',
    component: () => import(/* webpackChunkName: "EnvejeciendoBien" */ '../views/CuidandoTuSalud/EnvejeciendoBien.vue')
  },
  {
    path: '/hang-pack',
    name: 'HangPack',
    component: () => import(/* webpackChunkName: "Contacto" */ '../views/HangPack.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  // mode: 'abstract',
  base: process.env.BASE_URL,
  routes
})

export default router
